export const DATA_TUTORIAL_REDACTION = [
  {
    id: 0,
    title: 'Olá, vestibulando(a). Tudo bem com você?',
    text:
      '<p>Antes de começar a praticar, gostaríamos de explicar como funciona a nossa redação.</p>'
  },
  {
    id: 1,
    title: 'Os temas da redação',
    text:
      '<p>Nossa plataforma possui uma variedade de mais de 200 temas <strong>divididos por categorias</strong>, conforme é mostrado na imagem.</p> <p>Além disso, temos uma seleção com os <strong>temas mais desenvolvidos</strong> pelos alunos, que você pode encontrar na página inicial de redação.</p>',
    imgs: [
      {
        src: 'themes.svg',
        alt:
          'A imagem mostra a seção dos mais de 200 temas de redação divididos por categorias para o aluno escolher, localizado na página principal de redação.'
      }
    ]
  },
  {
    id: 2,
    title: 'Redações Nota 1000',
    text:
      '<p>Trazemos as redações nota mil dos alunos para que todos consigam entender como eles tiraram essa nota e ajudar a praticar para chegar lá. <strong>Elas estão disponíveis na página inicial e podem ser filtradas de acordo com o tema.</strong></p>',
    imgs: [
      {
        src: 'best-redactions.svg',
        alt:
          'A imagem mostra a seção top redação nota 1000 da plataforma, lozalizado na página principal de redação.'
      }
    ]
  },
  {
    id: 3,
    title: 'Enviando a sua redação',
    text:
      '<p>Na hora de praticar, você poderá escolher fazer sua redação <strong>com ou sem tempo.</strong> E ao escolher a <strong>opção com tempo</strong> é importante saber que <strong>quando o tempo acabar a sua redação será enviada automaticamente.</strong><p><p>Além disso, você poderá enviar a sua redação de duas formas: ou <strong>por imagem</strong>, ou <strong>por texto.</strong>',
    imgs: [
      {
        src: 'send-redaction.svg',
        alt:
          'A imagem mostra um modal localizado na página de instruções do tema da redação escolhida, onde é perguntado ao aluno o tempo para da redaçao e o formato que ele deseja enviar a redação, por imagem ou texto.'
      }
    ]
  },
  {
    id: 4,
    title: 'Créditos para envio da redação',
    text:
      '<p>Todo mês você terá <strong>uma quantidade de créditos disponíveis</strong> para o envio da sua redação, que varia de acordo com seu plano e pode ser visualizada na página inicial de redação.</p> <p><strong>Cada redação corrigida por nossos monitores um crédito será consumido</strong>. E após consumir todos os créditos você só poderá enviar redação para correção no mês seguinte quando <strong>os créditos são renovados</strong>.</p>',
    imgs: [
      {
        src: 'credits.svg',
        alt:
          'A imagem mostra a seção de créditos disponíveis do aluno para enviar redações, localizado na página principal de redação.'
      }
    ]
  },
  {
    id: 5,
    title: 'Correção da redação',
    text:
      '<p>Os nossos monitores vão corrigir e comentar a sua redação de acordo com as competências do ENEM <strong>em até 48 horas.</strong></p> <p> Você pode acompanhar os “status”  das suas redações <strong>pendentes ou já corrigidas</strong>. Mas, apenas as redações corrigidas podem ser visualizadas detalhadamente.</p>',
    imgs: [
      {
        src: 'correction.svg',
        alt:
          'A imagem mostra a página minhas redações onde ficam as redações enviadas pelo aluno. Podendo as as mesmas estarem na seção de aguardando correção, corrigidas ou rejeitadas.'
      }
    ]
  },
  {
    id: 6,
    title: 'Refazer a redação',
    text:
      '<p>Você pode refazer uma redação sobre um determinado tema mais de uma vez. Tanto com o objetivo de <strong>recorreção</strong>, desejando outra opinião em relação ao seu texto, quanto com o objetivo de <strong>refacção</strong>, corrigindo os problemas que foram apontados pelo monitor.</p> <p>Sendo que em ambos os <strong>seus créditos são consumidos</strong>.</p>'
  },
  {
    id: 7,
    title: 'Analisando o seu desempenho',
    text:
      '<p>Você pode monitorar o seu <strong>desempenho geral</strong> através de dois gráficos na sua seção de desempenho, o primeiro irá avaliar o seu desempenho de acordo <strong>com a média de cada competência que formam suas notas da redação</strong> e o segundo <strong>comparar a média das suas notas com a média geral da galera</strong>.</p>',
    imgs: [
      {
        src: 'performance.svg',
        alt:
          'A imagem mostra a seção meu desempenho localizado na página principal da redação. Contento a nota da última prova, quantidade de redações enviadas e um gráfico para analisar seu desempenho.'
      }
    ]
  },
  {
    id: 8,
    title: 'Conheceu nossa redação?',
    text:
      '<p>Agora que já sabe tudo o que precisa saber da nossa redação. Que tal escolher um tema e começar a praticar?</p>'
  }
];
